import { CarouselController } from "../../frontend/controllers/carousel_controller"

export default class extends CarouselController {
  static targets = ["pagerCurrent", "pagerTotal"]

  connect() {
    super.connect()
    this.pagerTotalTarget.textContent = this.slideTargets.length
  }

  selectedIndexValueChanged() {
    this.pagerCurrentTarget.textContent = this.selectedIndexValue + 1
  }
}
